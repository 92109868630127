import React,{ useState,useRef } from 'react'
import {SpinnerLoader} from './loading/Loader'
import emailjs from '@emailjs/browser';
export default function ContactForm() {

    const [formData,setFormData] = useState({
        name:'',
        email:'',
        message:''
    });
    const form = useRef();

    const [loading,setLoading] = useState(false);
    const [statusMessage,setStatusMessage] =  useState('');
    const [status,setStatus] = useState(false);

    const handleInput = (e) => {
   
        let newFormData = formData;
        newFormData[e.target.id] = e.target.value;
   
        setFormData({...newFormData})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatusMessage('');
        setLoading(true);


        try{
         
            const result = await emailjs.sendForm('Send_Me_Message','template_hcpyx5k',form.current,'user_QT3ae5FFgatOVsHclrYV0');
            setStatus(true);
            setStatusMessage('message sended');
        }
        catch(err){
            setStatus(false);
            setStatusMessage(err.text);
        }
        finally{
            setLoading(false);
        }
    
    }
    return (
        <div className="contact-form">
            <h5 className="SegoeUI-font semibold">Send me a Message</h5>

            <form autoComplete="off" ref={form} onSubmit={handleSubmit}>
                <div>
                    <input type="text" id="name" name="name" onChange={handleInput} value={formData.name} required/>
                    <label htmlFor="Name" className=""><h6>Your Name</h6></label>
                    <div className="input_underline"></div>
                </div>
                <div>
                    <input type="email" id="email" name="email" onChange={handleInput} value={formData.email} required/>
                    <label htmlFor="Email" className=""><h6>Email</h6></label>
                    <div className="input_underline"></div>
                </div>
                <div>
                    <input type="message" id="message" name="message" onChange={handleInput} value={formData.message} required/>
                    <label htmlFor="message" className=""><h6>Message</h6></label>
                    <div className="input_underline"></div>
                </div>
                {
                    statusMessage !== '' ? (
                        <div className={"status-message " + (status ? "success" : "fail") }>{statusMessage}</div>
                    ) : null
                }
                <button disabled={loading}>{loading ? <SpinnerLoader></SpinnerLoader> : "Send"}</button>
            </form>
            
        </div>
    )
}
