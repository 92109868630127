import React from 'react'
import Segment from "./segment/Segment"
export default function WhatIDo() {
    const segments = [
        {
            imgSrc:require("../../img/web_development.svg"),
            heading:"Web Development",
            learnMoreLink:"https://en.wikipedia.org/wiki/Web_development",
            detail:"We brought the design to life. Deciding how the website goes to interact with the audiences and how the layout goes responsive, Even adding transitions to smooth the experience."
        },
        {
            imgSrc:require("../../img/design.svg"),
            heading:"Web Ui Design",
            learnMoreLink:"https://en.wikipedia.org/wiki/User_interface",
            detail:"We use typography and choose elements purposefully to provide a comfortable experience. On top of that, we can let the user focus on the message we want to present."
        },
        
    ]
    return (
        <div className="what-i-do" id="what-i-do-page">
            <div className="wrapper">
                <h3 className="title Consolas-font bold">What I Do</h3>
                <div className="content">
                    {segments && segments.map(({imgSrc,heading,detail,learnMoreLink},index) => {
                        return(
                            <Segment key={index} imgSrc={imgSrc} heading={heading} detail={detail} learnMoreLink={learnMoreLink}></Segment>
                        )
                        
                    })}

                </div>
            </div>

        </div>
    )
}
