import React from 'react'

export default function poster() {
    return (
        <div className="poster">
            <div className="wrapper">
                <h1 className="Javanese-text-font">Get In Touch</h1>
                <p >Any project in mind ? Let me know. I would love to hear from you and I will reply as soon as possible</p>
            </div>
        
        </div>
    )
}
