import React from 'react'

function SpinnerLoader() {
    return (
        <div className="spinner-loader">
        
        </div>
    )
}


export {SpinnerLoader}